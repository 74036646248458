var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm mới nhân viên công ty",
        visible: _vm.dialogVisible,
        width: "750px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "createAdminData",
          attrs: {
            "label-width": "120px",
            model: _vm.createAdminData,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Username", prop: "username" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập username" },
                model: {
                  value: _vm.createAdminData.username,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.createAdminData,
                      "username",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "createAdminData.username",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Họ tên", prop: "fullName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập họ tên" },
                model: {
                  value: _vm.createAdminData.fullName,
                  callback: function ($$v) {
                    _vm.$set(_vm.createAdminData, "fullName", $$v)
                  },
                  expression: "createAdminData.fullName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { display: "inline-block", width: "40%" },
              attrs: { label: "Giới tính", prop: "gender" },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.createAdminData.gender,
                    callback: function ($$v) {
                      _vm.$set(_vm.createAdminData, "gender", $$v)
                    },
                    expression: "createAdminData.gender",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "Nam" } }, [_vm._v("Nam")]),
                  _c("el-radio", { attrs: { label: "Nữ" } }, [_vm._v("Nữ")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { float: "right", width: "50%" },
              attrs: { label: "Ngày bắt đầu", prop: "startDate" },
            },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  clearable: "",
                  format: "dd-MM-yyyy",
                  placeholder: "Chọn ngày",
                },
                model: {
                  value: _vm.createAdminData.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.createAdminData, "startDate", $$v)
                  },
                  expression: "createAdminData.startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Số điện thoại", prop: "phone" } },
            [
              _c("el-input", {
                attrs: { type: "number", placeholder: "Nhập số điện thoại" },
                model: {
                  value: _vm.createAdminData.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.createAdminData, "phone", $$v)
                  },
                  expression: "createAdminData.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Địa chỉ", prop: "address" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 2,
                  type: "textarea",
                  placeholder: "Nhập địa chỉ",
                },
                model: {
                  value: _vm.createAdminData.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.createAdminData, "address", $$v)
                  },
                  expression: "createAdminData.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Ghi chú", prop: "note" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 2,
                  type: "textarea",
                  placeholder: "Nhập ghi chú",
                },
                model: {
                  value: _vm.createAdminData.note,
                  callback: function ($$v) {
                    _vm.$set(_vm.createAdminData, "note", $$v)
                  },
                  expression: "createAdminData.note",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("createAdminData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("createAdminData")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }